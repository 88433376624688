import React, { useRef, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

function MyChart(props) {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    gradient.addColorStop(0, '#563DF1');
    gradient.addColorStop(1, '#A817B5');
    const currentYear = new Date().getFullYear();
    const myChart = new Chart(ctx, {  // 반드시 new Chart()로 생성
      type: 'line',
      data: {
        labels: [currentYear - 4, currentYear - 3, currentYear - 2, currentYear - 1, currentYear],
        datasets: [{
          label: '공시지가 (만원/㎡)',
          data: props.publicLandPriceList,
          borderColor: gradient,   // ← 그라디언트 적용
          backgroundColor: '#A817B5',
          fill: false,
          borderWidth: 1,         // 선 두께
          pointRadius: 2,         // 기본 점 크기
          pointHoverRadius: 2,    // 마우스 호버 시 점 크기
        }],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false, // 범례 제거
          },
          tooltip: {
            enabled: true,  // 툴팁은 활성화
          },
        },
        scales: {
          x: {
            // x축 라인 설정
            grid: {
              // 축선(바깥선)만 그리되, 내부 그리드 라인은 제거

              drawOnChartArea: true,  // 차트 내부의 가로선(그리드 라인)은 유지
              drawBorder: false,      // 바깥쪽 축선(경계선) 제거
            },
          },
          y: {
            // y축 라인 설정
            grid: {

              drawOnChartArea: true,  // 차트 내부의 세로선(그리드 라인)은 유지
              drawBorder: false,
            },
            // 자동으로 90,000 ~ 99,000 사이를 계산하되,
            // 표시되는 눈금이 최대 4개를 넘지 않도록 제한
            ticks: {
              maxTicksLimit: 5,
            },
            // 필요하다면, 아래처럼 범위를 조금 더 구체적으로 유도할 수 있음
            // suggestedMin: 90000,
            // suggestedMax: 100000,
          },
        },
      },
    });
    // 컴포넌트 언마운트 시 차트 제거
    return () => myChart.destroy();
  }, [props.publicLandPriceList]);

  return (
    <div className="chartContainer">
    
    <div className="chartTitle">5개년 공시지가 그래프</div>
    <div className="chartWrap">
      <canvas ref={chartRef} />
    </div>
    
    </div>
  );
}

export default MyChart;
