import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { mapUtil } from '../util/mapUtil.js';
import CmprsEstmt from "./CmprsEstmt";
import Consulting from "./Consulting";
import { useNavigate } from "react-router-dom";
import MyChart from "./MyChart.js";

function Information(props) {
    const [tabCount, setTabCount] = useState(0);
    const [tabACount, setTabACount] = useState(0);
    const [allowBuildingCount, setAllowBuildingCount] = useState(0);
    const tabMenu = ['토지정보', '건축정보', '예상공사비'];
    const [bookMarkState, setBookMarkState] = useState(false);
    const [consultingState, setConsultingState] = useState(false);
    const [bookMarkWindow, setBookMarkWindow] = useState(false);
    const [subCategoryWindow, setSubCategoryWindow] = useState(false);
    const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
    const [informationScrollValue, setInformationScrollValue] = useState(0);
    const [contentHeightArray, setContentHeightArray] = useState([]);
    const [informationHeight, setInformationHeight] = useState(300);
    const [touchStartY, setTouchStartY] = useState(0);
    const [touchDistanceY, setTouchDistanceY] = useState(0);
    const [selectedBigTabMenu, setSelectedBigTabMenu] = useState(0);
    const scrollBoxRef = useRef(null);
    const [CmprsEstmtState, setCmprsEstmtState] = useState(false);

    const navigate = useNavigate();
    const [randomTotalPrice, setRandomTotalPrice] = useState(0);
    function processNumber(number) {
        const integerPart = String(Math.floor(number));
        let billionsPart = integerPart.slice(0, -8);
        const thousandsPart = integerPart.slice(-8, -7);
        if (billionsPart === '') { billionsPart = '0' };
        const result = `${billionsPart}.${thousandsPart}억`
        return result;
    }
    function clickBookMark() {
        if (props.userInfo === null || props.addressInfo === undefined) { window.setModalMessage('로그인 후 이용가능합니다.'); setBookMarkState(false); navigate('/login', { state: { returnUrl: window.location.href } }); return false }
        const user = props.userInfo;
        setSubCategoryWindow(false);
        if (bookMarkState === false) {
            if (bookMarkWindow === false) {
                setBookMarkWindow(true);
            } else if (bookMarkWindow === true) {
                setBookMarkWindow(false);
            }
        } else if (bookMarkState === true) {
            const bookmark_id = props.addressInfo.name.jibunAddress.replace(/\s{2,}/gi, ' ');
            axios({
                method: 'post',
                url: process.env.REACT_APP_BOOKMARKDELETE_SERVER_URL,
                data: {
                    _id: user._id,
                    provider: user.provider,
                    bookmark_id: bookmark_id,
                    name: null,
                    plotAr: props.addressInfo.construct.PLOT_AR,
                    color: '#fff'
                },
                withCredentials: true
            }).then((response) => {
                console.log(response.data);
                props.setBookMarks(response.data);
                setBookMarkState(false);
            }).catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    window.setModalMessage('로그인 후 이용가능합니다.');
                    setBookMarkState(false);
                    navigate('/login', { state: { returnUrl: window.location.href } });
                }
            });

        }

    }
    function checkBookMark() {
        if (props.bookMarks === null || props.bookMarks === undefined || props.addressInfo === undefined) return false;
        setBookMarkState(false);
        if (props.bookMarks.length !== 0) {
            props.bookMarks.forEach((list_item, list_index) => {
                list_item.bookMarks.forEach((item, index) => {
                    if (item.bookmark_id.replace(/\s{2,}/gi, ' ') === props.addressInfo.name.jibunAddress.replace(/\s{2,}/gi, ' ')) {
                        console.log('북마크 있음');
                        setBookMarkState(true);
                    }
                });
            })
        }
    }
    function consulting_btn() {
        if (props.userInfo === null) { window.setModalMessage('로그인 후 이용가능합니다.'); setBookMarkState(false); navigate('/login', { state: { returnUrl: window.location.href } }); return false }
        if (consultingState === true) {
            setConsultingState(false);
        } else {
            setConsultingState(true);
        }
    }
    function houst_total_filter() {
        if (props.addressInfo.estimateList[allowBuildingCount].mainCategoryName === '단독주택' && props.addressInfo.construct.TOTAR > 400) {
            return (400)
        } else {
            return (props.addressInfo.construct.TOTAR)
        }
    }
    function tabClick(index) {
        const scrollBox = scrollBoxRef.current;
        if (!scrollBox) return;

        if (index === 0) {
            scrollBox.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            return;
        }
        // contentHeightArray: 각 섹션의 높이
        // offsets: i번째 섹션의 '상단'이 몇 px인지(누적합)
        const offsets = [0];
        for (let i = 0; i < contentHeightArray.length - 1; i++) {
            offsets.push(offsets[i] + contentHeightArray[i]);
        }
        // 원하는 섹션(예: index) 범위 보정
        const targetIndex = Math.min(index, offsets.length - 1);

        // (A) 섹션 상단, 섹션 높이
        const sectionTop = offsets[targetIndex];
        const sectionHeight = contentHeightArray[targetIndex];
        // (B) ‘섹션 중심’ 좌표
        let sectionCenter = sectionTop + sectionHeight / 2;

        // (C) 스크롤박스의 높이
        const containerHeight = scrollBox.offsetHeight;

        // (D) “섹션 중심을 스크롤박스 가운데”에 맞추는 scrollTop

        // (E) 경계 보정
        const maxScroll = scrollBox.scrollHeight - containerHeight;
        if (sectionCenter < 0) sectionCenter = 0;          // 첫 섹션이 짧은 경우 → 0
        if (sectionCenter > maxScroll) sectionCenter = scrollBox.scrollHeight; // 마지막 섹션이 짧은 경우 → 맨 아래


        // 스크롤 이동
        scrollBox.scrollTo({
            top: sectionCenter,
            behavior: 'smooth',
        });
        // 탭 상태 갱신
    }
    function scrollBoxScrollEvent(e) {
        e.preventDefault();
        setInformationScrollValue(e.target.scrollTop);
    }
    function handleTouchStart(e) {
        setTouchStartY(e.touches[0].clientY);
    };

    function handleTouchMove(e) {
        const currentY = e.touches[0].clientY;
        const distance = currentY - touchStartY;
        setTouchDistanceY(distance);
    };
    function handleTouchEnd(e) {
        let upDown = 'same';
        if (touchDistanceY > 50) {
          upDown = 'down';
        } else if (touchDistanceY < -50) {
          upDown = 'up';
        }
      
        // 현재 화면 높이 중, 상단 헤더나 기타 요소를 뺀 최대 사용 가능 높이를 계산
        // 예: 헤더 높이가 60px 정도라면:
        const headerHeight = 182; 
        const maxAvailableHeight = window.innerHeight - headerHeight;
        console.log(maxAvailableHeight);
      
        let newHeight = informationHeight; // 기존의 정보창 높이
      
        if (informationHeight === maxAvailableHeight) {
          if (touchDistanceY > 300) {
            newHeight = 20;
          } else {
            if (upDown === 'down') {
              newHeight = 300;
            } else if (upDown === 'up') {
              newHeight = maxAvailableHeight;
            }
          }
        } 
        else if (informationHeight === 300) {
          if (upDown === 'down') {
            newHeight = 20;
          } else if (upDown === 'up') {
            newHeight = maxAvailableHeight;
          }
        } 
        else if (informationHeight === 20) {
          if (touchDistanceY < -300) {
            newHeight = maxAvailableHeight;
          } else {
            if (upDown === 'down') {
              newHeight = 20;
            } else if (upDown === 'up') {
              newHeight = 300;
            }
          }
        }
      
      
        setInformationHeight(newHeight);
        setTouchDistanceY(0);
      }
    function checkTabHeight() {
        const scrollBox = scrollBoxRef.current;
        if (scrollBox === null) return false;
        const scrollBoxHeight = scrollBox.offsetHeight;
        if (scrollBox !== null) {
            const newArray = []
            scrollBox.querySelectorAll('.content_wrap').forEach((item, index) => {
                let height = 0;
                if (index === 0) {
                    height = item.offsetHeight + 80 + 1 - scrollBoxHeight / 2;
                } else {
                    height = item.offsetHeight + 80 + 1;
                }
                newArray.push(height);
            });
            setContentHeightArray(newArray);
            console.log(newArray);
        }

    }
    let resetTime;
    function resizeHandle() {
        setWindowInnerWidth(window.innerWidth);
        setTabCount(0);
        clearTimeout(resetTime);
        resetTime = setTimeout(checkTabHeight, 1000);
    }
    useEffect(() => {
        setAllowBuildingCount(0);
        props.setLoadingModule(false);
        if (props.addressInfo !== undefined && window.infoWindow) {
            if (props.addressInfo.estimateList[0].mainCategoryName == undefined) {
                window.infoWindow.setContent([
                    `<div class="infoWindow">
                        <div class="allow deny">건축불가</div>
                    </div>`
                ].join('\n'));

            } else {
                let totalPrice = 0;
                if (props.testing !== true) {
                    totalPrice = applyRandomError(props.addressInfo.estimateList[allowBuildingCount].estimate.totalNumber);
                    props.setRandomPrice(numberToKoreanCurrency(totalPrice));
                } else {
                    totalPrice = koreanCurrencyToNumber(props.randomPrice);
                }
                window.infoWindow.setContent([
                    `<div class="infoWindow">
                        <div class="name">${props.addressInfo.estimateList[allowBuildingCount].mainCategoryName}</div> 
                        <div class="allow">건축가능</div>
                        <div class="estimateTotal" style=" ">${processNumber(totalPrice)}</div>
                    </div>`
                ].join('\n'));
            }
        }
        checkBookMark();
        console.log(props.addressInfo);
        checkTabHeight();
        setTabACount(0);
    }, [props.addressInfo])
    useEffect(() => {
        if (props.addressInfo !== undefined && window.infoWindow) {
            let totalPrice = 0;
            if (props.testing !== true) {
                totalPrice = applyRandomError(props.addressInfo.estimateList[allowBuildingCount].estimate.totalNumber);
                props.setRandomPrice(numberToKoreanCurrency(totalPrice));
            } else {
                totalPrice = koreanCurrencyToNumber(props.randomPrice);
            }
            window.infoWindow.setContent([
                `<div class="infoWindow">
                    <div class="name">${props.addressInfo.estimateList[allowBuildingCount].mainCategoryName}</div> 
                    <div class="allow">건축가능</div>
                    <div class="estimateTotal">${processNumber(totalPrice)}</div>
                </div>`
            ].join('\n'));
        }

    }, [allowBuildingCount])
    useEffect(() => {

        window.addEventListener('resize', resizeHandle);
        return (() => {
            window.removeEventListener('resize', resizeHandle);
        });
    }, []);

    useEffect(() => {
        const scrollBox = scrollBoxRef.current;
        if (!scrollBox) return;

        const st = scrollBox.scrollTop;        // 현재 스크롤
        const ch = scrollBox.clientHeight;     // 보이는 영역 높이
        const sh = scrollBox.scrollHeight;     // 총 스크롤 높이

        // 1) 스크롤이 하단 가까이 와 있다면 => 마지막 탭(예: index=2)으로
        if (st + ch >= sh - 10) {
            setTabCount(2);
            return;
        }

        // 2) 그 외 구간은 기존 "가운데 계산" 로직대로 구간 분기
        const before_tab_01 = contentHeightArray[0];
        let before_tab_02 = before_tab_01 + contentHeightArray[1];

        if (st < before_tab_01) {
            setTabCount(0);
        } else if (st < before_tab_02) {
            setTabCount(1);
        } else {
            // 여기까지 왔는데도 '하단 근접'이 아니면,
            // (만약 섹션이 3개라면) setTabCount(2)로 처리 가능
            setTabCount(2);
        }
    }, [informationScrollValue]);


    useEffect(() => {
        const scrollBox = scrollBoxRef.current;
        if (scrollBox !== null && props.bookMarkInfoWindow === false) {
            setInformationScrollValue(0);
        }
    }, [props.bookMarkInfoWindow]);



    function applyRandomError(price, randomNumber = 9) {
        const randomErrorPercentage = Math.random() * randomNumber; // 0%에서 9% 사이의 랜덤 값
        const isPositiveError = Math.random() >= 0.5; // 오차를 플러스 또는 마이너스로 적용할지 결정
        const errorAmount = Math.floor((price * randomErrorPercentage) / 100); // 오차 금액 계산, 소수점 버림

        return isPositiveError ? price + errorAmount : price - errorAmount; // 랜덤 오차 적용
    }
    function numberToKoreanCurrency(number) {
        let units = ['원', '만', '억', '조'];
        let result = '';
        let unitIndex = 0;
        number = Math.floor(number); // 소수점 이하 제거

        while (number > 0) {
            let chunk = number % 10000;
            if (chunk > 0) {
                result = chunk + units[unitIndex] + ' ' + result;
            }
            unitIndex++;
            number = Math.floor(number / 10000);
        }

        return result.trim();
    }
    function koreanCurrencyToNumber(koreanCurrency) {
        const units = { '억': 1e8, '만': 1e4, '원': 1 };
        let total = 0;

        koreanCurrency = koreanCurrency.replace(/[^0-9억만원\s]/g, ""); // "9억 4292만 2053원" 형태 유지

        const parts = koreanCurrency.split(' ').filter(Boolean); // 공백 기준으로 분리
        for (let part of parts) {
            let unit = part.slice(-1); // 마지막 글자를 단위로 확인
            let number = parseInt(part.slice(0, -1), 10); // 단위 전 숫자를 추출

            if (units[unit] && !isNaN(number)) {
                total += number * units[unit];
            }
        }
        return total;
    }

    return (
        <div className={`informationWrap`}
            style={windowInnerWidth > 1024 ? {} : { height: `${informationHeight - touchDistanceY}px` }}
        >
            <div className={`bookmark_window_cover ${bookMarkWindow === true || subCategoryWindow === true || consultingState === true || CmprsEstmtState === true ? "" : "hidden"}`} onClick={() => { setBookMarkWindow(false); setSubCategoryWindow(false); setConsultingState(false); setCmprsEstmtState(false);}}></div>
            <div className="M_infoBtn_wrap"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}>
                <div className="M_infoBtn">
                    <p className="infoBtn_line"></p>
                </div>
            </div>
            {props.addressInfo === undefined ?
                <div className="loadingModule">
                    <svg className="loadingImage" width="184" height={informationHeight === 20 ? "0" : "90"} viewBox="0 0 181 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M180,79V43L90,2.4L1.3,43V79H180Z" />
                    </svg>
                </div> :
                <>
                    {props.loadingModule === true ?
                        <div className={`loadingModule`}>
                            <svg className="loadingImage" width="184" height={informationHeight === 20 ? "0" : "90"} viewBox="0 0 181 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M180,79V43L90,2.4L1.3,43V79H180Z" />
                            </svg>
                        </div> : ''}
                    {props.bookMarkInfoWindow === true && props.userInfo !== null ?
                        <BookMarkList bookMarks={props.bookMarks} setLoadingModule={props.setLoadingModule} infoClick={props.infoClick} onClick={props.onClick} informationHeight={informationHeight} CmprsEstmtState={CmprsEstmtState} setCmprsEstmtState={setCmprsEstmtState}/> :
                        <>
                            <div className={`noneBox`}>
                                <div className="topWrap">
                                    <div className="titleBox">{props.addressInfo.name.jibunAddress}</div>
                                    <div className='subTitleBox'>{props.addressInfo.name.roadAddress}</div>
                                    <div className='btns btn_book_mark' onClick={clickBookMark}><img className="book_mark info_bookmark" src={bookMarkState === true ? process.env.PUBLIC_URL + "images/map/icon_bookmark_allblack.svg" : process.env.PUBLIC_URL + "images/map/icon_bookmark_black.svg"}></img></div>
                                    {props.addressInfo.land.landType1 !== '개발제한구역' && (
                                        <div className="allowWrap">
                                            <AllowBuildingList addressInfo={props.addressInfo} allowBuildingCount={allowBuildingCount} setAllowBuildingCount={setAllowBuildingCount} />
                                            <div className={subCategoryWindow === true ? "more_btn open" : "more_btn"} onClick={() => { setSubCategoryWindow(!subCategoryWindow); setBookMarkWindow(false); }}>{windowInnerWidth <= 1024 ? "상세" : "상세"}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="bottomWrap">

                                    {props.addressInfo.land.landType1 === '개발제한구역' ?
                                        <div className="green_belt">
                                            개발제한구역으로<br></br>건축이 불가능한 토지입니다.
                                        </div>
                                        :
                                        <>

                                            <div className="bigTabMenuWrap">
                                                <div className={selectedBigTabMenu === 0 ? "bigTabMenu selected" : "bigTabMenu"} onClick={() => { setSelectedBigTabMenu(0) }}>신축정보</div>
                                                <div className={selectedBigTabMenu === 1 ? "bigTabMenu selected" : "bigTabMenu"} onClick={() => { setSelectedBigTabMenu(1) }}>건물정보</div>
                                            </div>
                                            {selectedBigTabMenu === 0 ?
                                                <div className="infoBox">
                                                    <ul className='tabMenuUl'>
                                                        {
                                                            tabMenu.map((item, index) => {
                                                                return <li key={index} className={`tabMenu ${tabCount == index ? "active" : ""}`} onClick={() => { tabClick(index) }}>{item}</li>
                                                            })
                                                        }
                                                    </ul>
                                                    <div className="content_container" ref={scrollBoxRef} onScroll={scrollBoxScrollEvent}>

                                                        <>
                                                            <div className="content_wrap">
                                                                <div className="content_without_chart add_line">
                                                                    <div className="content_box">
                                                                        <div className="title">토지 면적</div>
                                                                        <div className="content">{props.addressInfo.land.size}㎡<span>({(props.addressInfo.land.size * 0.3025).toFixed(2)}평)</span></div>
                                                                    </div>
                                                                    <div className="content_box">
                                                                        <div className="title">지목</div>
                                                                        <div className="content">{props.addressInfo.land.type}</div>
                                                                    </div>
                                                                    <div className="content_box">
                                                                        <div className="title">용도</div>
                                                                        <div className="content">{props.addressInfo.land.landType1}</div>
                                                                    </div>
                                                                    <div className="content_box">
                                                                        <div className="title">건축가능용도</div>
                                                                        <div className="content">{props.addressInfo.allowBuildingList.allowBuildingListSubCategory[allowBuildingCount].name}</div>
                                                                    </div>
                                                                    <div className="content_box">
                                                                        <div className="title">공시지가</div>
                                                                        <div className="content">{Number(props.addressInfo.publicLandPriceList[0]).toLocaleString()}(원/㎡)</div>
                                                                    </div>
                                                                    <div className="content_box">
                                                                        <div className="title">지형높이</div>
                                                                        <div className="content">{props.addressInfo.land.landHeight}</div>
                                                                    </div>
                                                                    <div className="content_box">
                                                                        <div className="title">지형형상</div>
                                                                        <div className="content">{props.addressInfo.land.landFromCode}</div>
                                                                    </div>
                                                                    <div className="content_box">
                                                                        <div className="title">데이터기준일자</div>
                                                                        <div className="content">{props.addressInfo.land.lastUpdateDate.split(" ")[0]}</div>
                                                                    </div>
                                                                </div>
                                                                <MyChart publicLandPriceList={props.addressInfo.publicLandPriceList} />
                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="content_wrap add_line">
                                                                <div className="content_box ">
                                                                    <div className="title">토지 면적</div>
                                                                    <div className="content">{props.addressInfo.land.size}㎡<span>({(props.addressInfo.land.size * 0.3025).toFixed(2)}평)</span></div>
                                                                </div>
                                                                <div className="content_box">
                                                                    <div className="title">건축 면적</div>
                                                                    <div className="content">{props.addressInfo.construct.PLOT_AR}㎡<span>({(props.addressInfo.construct.PLOT_AR * 0.3025).toFixed(2)}평)</span></div>
                                                                </div>
                                                                <div className="content_box">
                                                                    <div className="title">연면적</div>
                                                                    <div className="content">{houst_total_filter()}㎡<span>({(houst_total_filter() * 0.3025).toFixed(2)}평)</span></div>
                                                                </div>
                                                                <div className="content_box">
                                                                    <div className="title">건폐율</div>
                                                                    <div className="content">{props.addressInfo.gyData.g}%</div>
                                                                </div>
                                                                <div className="content_box">
                                                                    <div className="title">용적률</div>
                                                                    <div className="content">{props.addressInfo.gyData.y}%</div>
                                                                </div>

                                                            </div>
                                                            <div className="row"></div>
                                                            <div className="content_wrap add_line">

                                                                <div className="content_box totalPrice">
                                                                    <div className="title">총액</div>
                                                                    <div className="content">{props.randomPrice}</div>
                                                                </div>
                                                                <div className="content_box">
                                                                    <div className="title">설계비</div>
                                                                    <div className="content">{props.addressInfo.estimateList[allowBuildingCount].estimate.extra}</div>
                                                                </div>
                                                                <div className="content_box">
                                                                    <div className="title">자재비</div>
                                                                    <div className="content">{props.addressInfo.estimateList[allowBuildingCount].estimate.materialsCost}</div>
                                                                </div>
                                                                <div className="content_box">
                                                                    <div className="title">인건비</div>
                                                                    <div className="content">{props.addressInfo.estimateList[allowBuildingCount].estimate.personnelCost}</div>
                                                                </div>
                                                                <div className="content_box">
                                                                    <div className="title">경비</div>
                                                                    <div className="content">{props.addressInfo.estimateList[allowBuildingCount].estimate.expense}</div>
                                                                </div>
                                                            </div>
                                                        </>

                                                    </div>
                                                </div>
                                                :
                                                <div className="infoBox">
                                                    {props.addressInfo.buildingInfoList.length === 0 && (
                                                        <div className="green_belt">
                                                            건물정보가 없습니다.
                                                        </div>
                                                    )
                                                    }
                                                    <div className="tabMenu_a">
                                                        {
                                                            (props.addressInfo.buildingInfoList && props.addressInfo.buildingInfoList.langth !== 0) && (
                                                                props.addressInfo.buildingInfoList.map((item, index) => {
                                                                    return (
                                                                        <div className={`tab_wrap ${tabACount === index ? "select" : ""}`} >
                                                                            <div key={index} className='tab' onClick={() => { setTabACount(index) }}>{`건물정보 ${index}`}</div>
                                                                        </div>
                                                                    )
                                                                })

                                                            )
                                                        }

                                                    </div>
                                                    <div className="content_container">
                                                        {props.addressInfo.buildingInfoList.length > 0 && props.addressInfo.buildingInfoList.map((building, index) => {
                                                            // 현재 선택된 탭과 index가 일치할 때만 보여줌
                                                            if (tabACount === index) {
                                                                // 건축 면적, 평 계산
                                                                const buildingArea = parseFloat(building.buildingArea || "0");
                                                                const pyung = (buildingArea * 0.3025).toFixed(2);


                                                                return (
                                                                    <div className="content_wrap add_line" key={index}>
                                                                        <div className="content_box">
                                                                            <div className="title">건물명</div>
                                                                            <div className="content">{building.buildingName}</div>
                                                                        </div>
                                                                        <div className="content_box">
                                                                            <div className="title">건물동명</div>
                                                                            <div className="content">{building.buildingDongName}</div>
                                                                        </div>
                                                                        <div className="content_box">
                                                                            <div className="title">건축 면적</div>
                                                                            {/* buildingArea + '㎡' 로 할 수도 있고, 서버에서 이미 붙였으면 그대로 사용 */}
                                                                            <div className="content">
                                                                                {building.buildingArea}㎡
                                                                                <span>({pyung}평)</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="content_box">
                                                                            <div className="title">건추물구조명</div>
                                                                            <div className="content">{building.structureType}</div>
                                                                        </div>
                                                                        <div className="content_box">
                                                                            <div className="title">주도용도명</div>
                                                                            <div className="content">{building.mainUsage}</div>
                                                                        </div>
                                                                        <div className="content_box">
                                                                            <div className="title">건물높이</div>
                                                                            <div className="content">{building.buildingHeight === "0.0" ? "정보 없음":building.buildingHeight}</div>
                                                                        </div>
                                                                        <div className="content_box">
                                                                            <div className="title">지상층수</div>
                                                                            <div className="content">{building.groundFloors}</div>
                                                                        </div>
                                                                        <div className="content_box">
                                                                            <div className="title">지하층수</div>
                                                                            <div className="content">{building.undergroundFloors}</div>
                                                                        </div>
                                                                        <div className="content_box">
                                                                            <div className="title">허가일자</div>
                                                                            <div className="content">{building.permitDate}</div>
                                                                        </div>
                                                                        <div className="content_box">
                                                                            <div className="title">총주차대수</div>
                                                                            <div className="content">{building.totParkngCo}대</div>
                                                                        </div>
                                                                        <div className="content_box">
                                                                            <div className="title">총주차장면적</div>
                                                                            <div className="content">{building.totPrkplceAr}</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                            // 탭이 아닌 경우 렌더링 안 함
                                                            return null;
                                                        })}
                                                    </div>
                                                </div>
                                            }
                                            <div className={`btns btn_b ${informationHeight !== 20 ? "" : "close"}`} onClick={consulting_btn}>
                                                컨설팅 받아보기
                                                <div className="left-chevron"></div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                            {consultingState === true ?
                                <>
                                    <Consulting userInfo={props.userInfo} setConsultingState={setConsultingState} infoClick={props.infoClick} onClick={props.onClick} />
                                    <div className="consulting_background" onClick={() => { setConsultingState(false) }}></div>
                                </>
                                : ''}
                        </>
                    }
                </>
            }
            {props.userInfo === null || props.addressInfo === undefined || props.bookMarks === undefined || props.bookMarks === null ? '' :
                <BookMarkWindow userInfo={props.userInfo} bookMarkWindow={bookMarkWindow} addressInfo={props.addressInfo} setBookMarkState={setBookMarkState} setBookMarks={props.setBookMarks} bookMarks={props.bookMarks} setBookMarkWindow={setBookMarkWindow} />
            }
            {props.addressInfo === undefined ? '' :
                <AllowBuildingListSubCategory subCategoryWindow={subCategoryWindow} allowBuildingCount={allowBuildingCount} addressInfo={props.addressInfo} setSubCategoryWindow={setSubCategoryWindow} />
            }
        </div>
    )
}

function BookMarkList(props) {
    const [tabIndex, setTabIndex] = useState(0);
    const bookMarkTabMenu = ['전체'];
    const [selectedOptions, setSelectedOptions] = useState([]);

    function bookmarkClick(bookmark_id) {
        mapUtil.searchAddressToCoordinate(bookmark_id);
        props.setLoadingModule(true);
    }

    function EstmtBtnClick() {
        if (props.CmprsEstmtState === true) {
            props.setCmprsEstmtState(false);
        } else {
            props.setCmprsEstmtState(true);
        }
    }

    const handleCheckboxChange = (e) => {
        const value = JSON.parse(e.target.value);
        const checked = e.target.checked;

        if (checked) {
            if (selectedOptions.length < 4) {
                setSelectedOptions([...selectedOptions, value]);
            } else {
                e.target.checked = false;
            }
        } else {
            setSelectedOptions(selectedOptions.filter(option => option.bookmark_id !== value.bookmark_id));
        }
    };
    return (
        <>
            <div className={`infoBox cover_hidden`} id="bookmark">
                <ul className="tabMenuUl_b">
                    <li className={`tabMenu ${tabIndex === 0 ? "active" : ""}`} style={{ width: 100 / (props.bookMarks.length + 1) + '%' }} onClick={() => { setTabIndex(0) }}>전체</li>
                    {props.bookMarks.map((item, index) => {
                        return <li key={index} className={`tabMenu ${tabIndex === index + 1 ? "active" : ""}`} style={{ width: 100 / (props.bookMarks.length + 1) + '%' }} onClick={() => { setTabIndex(index + 1) }}>{item.bookMarkListName}</li>
                    })}
                </ul>
                <div className="tabContent bookmarks">
                    <ul className="bookmark_list">
                        {props.bookMarks.length === 0 ?
                            <div className="bookmark_empty_message">
                                북마크를 등록해주세요!
                            </div>
                            : tabIndex === 0 ? props.bookMarks.map((list_item, list_index) => {
                                return list_item.bookMarks.map((item, index) => {
                                    return (
                                        <li key={index} className='bookmark' onClick={(e) => { console.log(e.target.tagName); if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'LABEL') { bookmarkClick(item.bookmark_id) } }}>
                                            <div className="color" style={{ background: list_item.bookMarkListColor }}></div>
                                            <div className="name">{item.landName}</div>
                                            <div className="title">{item.bookmark_id}</div>
                                            <div className="plot">대지면적 : {item.plotAr}㎡ ({(item.plotAr * 0.3025).toFixed(2)}평)</div>
                                            <input type="checkbox" id={`check${list_index}${index}`} className="checkbox" value={JSON.stringify({ ...item, bookMarkListColor: list_item.bookMarkListColor })} onChange={handleCheckboxChange}></input>
                                            <label htmlFor={`check${list_index}${index}`}></label>
                                        </li>
                                    )
                                })
                            }) : ''
                        }
                        {props.bookMarks.map((list_item, list_index) => {
                            if (tabIndex === list_index + 1) {
                                return list_item.bookMarks.map((item, index) => {
                                    return (
                                        <li key={index} className='bookmark' onClick={(e) => { console.log(e.target.tagName); if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'LABEL') { bookmarkClick(item.bookmark_id) } }}>
                                            <div className="color" style={{ background: list_item.bookMarkListColor }}></div>
                                            <div className="name">{item.landName}</div>
                                            <div className="title">{item.bookmark_id}</div>
                                            <div className="plot">대지면적 : {item.plotAr}㎡ ({(item.plotAr * 0.3025).toFixed(2)}평)</div>
                                            <input type="checkbox" id={`check${index}`} className="checkbox" value={JSON.stringify({ ...item, bookMarkListColor: list_item.bookMarkListColor })} onChange={handleCheckboxChange}></input>
                                            <label htmlFor={`check${index}`}></label>
                                        </li>
                                    )
                                })
                            }
                        })}

                    </ul>
                </div>
                <div className={`btns btn_b ${props.informationHeight !== 20 ? "" : "close"}`} onClick={EstmtBtnClick}>견적 비교하기
                <div className="left-chevron"></div></div>
            </div>
            {props.CmprsEstmtState === false ? '' : <CmprsEstmt selectedOptions={selectedOptions} bookMarks={props.bookMarks} onClick={props.onClick} infoClick={props.infoClick} />}
        </>

    )
}

function AllowBuildingList(props) {
    function handleChangeSelect(e) {
        props.setAllowBuildingCount(e.target.value);
    }
    return (
        <div className="content selectBox">
            {props.addressInfo.allowBuildingList.allowBuildingListSubCategory.length === 0 ?
                "건축불가" :
                <div className="selectWrap">
                    <div className="allowBuildingTitle">건축가능용도</div>
                    <select className="allowBuildingList" value={props.allowBuildingCount} onChange={handleChangeSelect}>
                        {props.addressInfo.allowBuildingList.allowBuildingListSubCategory.map((item, index) => {
                            return <option key={index} value={index}>{item.name}</option>;
                        })}
                    </select>

                </div>
            }
        </div>
    )
}

function BookMarkWindow({ bookMarkWindow, addressInfo, userInfo, setBookMarks, setBookMarkState, bookMarks, setBookMarkWindow }) {
    const [newList, setNewList] = useState(true);
    const [newListLength, setNewListLength] = useState(0);
    const [nameLength, setNameLength] = useState(0);
    const [memoLength, setMemoLength] = useState(0);
    const [selectedColor, setSelectedColor] = useState();
    const [bookMarkListBtnState, setBookMarkListBtnState] = useState(false);
    const [bookMarkBtnState, setBookMarkBtnState] = useState(false);
    const navigate = useNavigate();

    const color_list = [
        '#f25cb6', '#1DB075', '#FECE25', '#22618C', '#8A94BE'
    ]

    function listBtnCheck() {
        if (selectedColor !== undefined && newListLength !== 0) {
            setBookMarkListBtnState(true);
        } else {
            setBookMarkListBtnState(false);
        }
    }

    function checkLength(e, setNameLength, maxLength) {
        let length = e.target.value.length;
        if (length >= maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
            length = maxLength;
        }
        setNameLength(length);
    }

    function addBookMarkList() {
        if (userInfo === null || addressInfo === undefined) { window.setModalMessage('로그인 후 이용가능합니다.'); navigate('/login', { state: { returnUrl: window.location.href } }); return false }
        const user = userInfo;
        const form = document.bookMark;
        const color = color_list[selectedColor];
        const listName = form.listName.value;
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_SERVER}/bookmark/addList`,
            data: {
                _id: user._id,
                provider: user.provider,
                listName: listName,
                color: color
            },
            withCredentials: true
        }).then((response) => {
            console.log(response.data);
            setBookMarks(response.data);
        }).catch((error) => {
            console.log(error);
            if (error.response.status === 401) {
                window.setModalMessage('로그인 후 이용가능합니다.');
                setBookMarkState(false);
                navigate('/login', { state: { returnUrl: window.location.href } });
            }
        });

    }

    function addBookMark() {
        if (userInfo === null || addressInfo === undefined) { window.setModalMessage('로그인 후 이용가능합니다.'); navigate('/login', { state: { returnUrl: window.location.href } }); return false }
        const user = userInfo;
        const form = document.bookMark;
        const bookMarkListIndex = form.book_mark_list.value;
        const bookMarkMemo = form.bookMarkMemo.value;
        let landName;
        if (form.landName.value === '') {
            return false;
        } else {
            landName = form.landName.value;
        }
        const bookmark_id = addressInfo.name.jibunAddress.replace(/\s{2,}/gi, ' ');

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_SERVER}/bookmark/add`,
            data: {
                _id: user._id,
                provider: user.provider,
                bookmark_id: bookmark_id,
                landName: landName,
                plotAr: addressInfo.construct.PLOT_AR,
                bookMarkListIndex: bookMarkListIndex,
                bookMarkMemo: bookMarkMemo
            },
            withCredentials: true
        }).then((response) => {
            console.log(response.data);
            setBookMarks(response.data);
            setBookMarkWindow(false);
            setBookMarkState(true);
        }).catch((error) => {
            console.log(error);
            if (error.response.status === 401) {
                window.setModalMessage('로그인 후 이용가능합니다.');
                setBookMarkState(false);
                navigate('/login', { state: { returnUrl: window.location.href } });
            }
        });
    }

    useEffect(() => {
        if (bookMarks.length >= 3 && newList === true) {
            setTimeout(() => { setNewList(false) }, 1000);
        }
    }, [newList, bookMarks]);

    useEffect(() => {
        listBtnCheck();
    }, [selectedColor, newListLength]);

    return (
        <div className={bookMarkWindow === true ? 'bookmark_window' : 'bookmark_window hidden'} >
            <form name="bookMark">
                <div className="list_add_wrap" onClick={() => { setNewList(!newList) }}>
                    새 리스트 추가
                    <div className={newList === true ? 'list_icon rotate' : 'list_icon'}></div>
                </div>
                <div className="content_wrap">
                    {newList === true ?
                        bookMarks.length >= 3 ? <div className="warningMessage">리스트는 최대 3개까지 입니다.</div> :
                            <>
                                <div className="name_wrap">
                                    <input className="bookmark_name" name="listName" type="text" placeholder="새 리스트명을 입력해주세요" onChange={(e) => { checkLength(e, setNewListLength, 6) }}></input>
                                    <div className="text_counter"><span>{newListLength}</span>/6</div>
                                </div>
                                <div className="color_wrap">
                                    <div className="title">색상 선택</div>
                                    <ul className="color_ul clearfixed">
                                        {color_list.map((item, index) => {
                                            return (
                                                <li className={selectedColor === index ? 'color checked' : 'color'} style={{ background: item }} key={index} onClick={() => { setSelectedColor(index) }}></li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className={bookMarkListBtnState === true ? "submit_btn" : "submit_btn disabled"} onClick={addBookMarkList}>리스트 저장</div>
                            </> : ''

                    }
                    <div className="name_wrap">
                        <input className="bookmark_name" name="landName" type="text" placeholder="토지의 별명을 입력해주세요." onChange={(e) => { checkLength(e, setNameLength, 20) }}></input>
                        <div className="text_counter"><span>{nameLength}</span>/20</div>
                    </div>
                    <div className="list_wrap">
                        <ul className="bookmark_add_list_con">
                            {bookMarks.length === 0 ?
                                <li className="bookmark_add_list clearfixed">
                                    <div className="warningMessage">새 리스트를 추가해 주세요.</div>
                                </li> :
                                bookMarks.map((item, index) => {
                                    return (
                                        <li className="bookmark_add_list clearfixed" key={index}>
                                            <div className="color" style={{ background: item.bookMarkListColor }}></div>
                                            <div className="title">{item.bookMarkListName}</div>
                                            <input type="radio" id={`check_add_bookmark_${index}`} className="checkbox" name="book_mark_list" value={index} onClick={() => { setBookMarkBtnState(true) }}></input>
                                            <label htmlFor={`check_add_bookmark_${index}`}></label>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="memo_wrap">
                        <div className="title">메모</div>
                        <div className="memo_con">
                            <textarea className="memo" name="bookMarkMemo" onChange={(e) => { checkLength(e, setMemoLength, 30) }}></textarea>
                            <div className="text_counter"><span>{memoLength}</span>/30</div>
                        </div>
                        <div className={(bookMarkBtnState === true && document.bookMark.landName.value !== '') ? "submit_btn" : "submit_btn disabled"} onClick={addBookMark}>저장</div>
                    </div>
                </div>

            </form>
        </div>
    )
}

function AllowBuildingListSubCategory({ subCategoryWindow, allowBuildingCount, addressInfo, setSubCategoryWindow }) {
    return (
        <div className={subCategoryWindow === true ? 'subcategory_window' : 'subcategory_window hidden'}>
            <div className="list_add_wrap">
                건축용도 가능여부
            </div>
            <div className="close_btn" onClick={() => { setSubCategoryWindow(false) }}></div>
            <div className="content_wrap">
                {addressInfo.allowBuildingList.allowBuildingListSubCategory.length === 0 ? '' :
                    addressInfo.allowBuildingList.allowBuildingListSubCategory[allowBuildingCount].list.map((item, index) => {
                        let allowAble;
                        if (!item.조건제한예외사항) {
                            allowAble = item.가능여부
                        } else {
                            allowAble = '건축제한'
                        }
                        return (
                            <li
                                className={allowAble === '건축제한' ? "sub_category_list pointer" : "sub_category_list"}
                                key={index}
                                onClick={(e) => {
                                    // 가장 가까운 li 요소 찾기
                                    const liEl = e.currentTarget.closest('li');
                                    // li 안에 있는 .warning_txt 요소를 찾고, 존재하면 클래스 토글
                                    const warningTxtEl = liEl.querySelector('.warning_txt');
                                    if (warningTxtEl) {
                                        warningTxtEl.classList.toggle('open');
                                    }
                                }}
                            >
                                <div className="list_wrap">
                                    <div className="title_wrap">
                                        <div className="title">{item.토지이용명.replace(/。/g, '')}</div>
                                        {item.조건제한예외사항 !== undefined && (
                                            <div
                                                className="warning"
                                            >
                                                [조건·제한·예외사항]
                                            </div>
                                        )}
                                    </div>
                                    <div className="allowable">{allowAble}</div>
                                </div>
                                {allowAble === '건축제한' && (
                                    <div className="warning_txt">
                                        {`(조건·제한·예외사항 : ${item.조건제한예외사항})`}
                                    </div>
                                )}
                            </li>
                        )
                    })
                }
            </div>
            <div className="eum_notice">보다 자세한 내용은 토지이음에서 확인해주시길 바랍니다.</div>
            <div className="btn_wrap">
                <a className="btn_c black" target="_blank" href={`http://www.eum.go.kr/web/ar/lu/luLandDet.jsp?selSido=11&selSgg=620&selUmd=0102&selRi=00&landGbn=1&bobn=501&bubn=33&sggcd=&isNoScr=script&mode=search&selGbn=umd&s_type=1&add=land&pnu=${addressInfo.pnu}`}>토지이음 바로가기</a>
            </div>
        </div>
    )
}

export default Information;