// src/News.jsx
import React, { useState, useEffect, useRef } from "react";
import Header from "./Header"; // 필요한 경우

import "../css/news.css"; // 기존에 사용하던 news.css

// 커스텀 뉴스만 (유튜브, 블로그 제외)
const customNews = [
    {
        url: "http://www.powerkoreadaily.com/news/articleView.html?idxno=526686",
        title: "투명하고 똑똑한 IT 건축 플랫폼 ‘프롭메이트’ 부동산 개발의 새로운 패러다임 열다",
        thumbnail: process.env.PUBLIC_URL + "images/526686.png",
        publishedAt: "2025-01-22T08:00:00Z",
        category: "NEWS",
    },
    {
        url: "https://www.gokorea.kr/news/articleView.html?idxno=817698",
        title: "프롭메이트, ‘2024 대한민국 지식재산대전’에서 AI 토지정보 제공 시스템 선봬",
        thumbnail: process.env.PUBLIC_URL + "images/817698.png",
        publishedAt: "2025-01-22T12:00:00Z",
        category: "NEWS",
    },
    {
        url: "https://www.woodkorea.co.kr/news/articleView.html?idxno=82766",
        title: "프롭메이트, 'NIPA 지역 성과보고회' 성공적 참여",
        thumbnail: process.env.PUBLIC_URL + "images/82766.png",
        publishedAt: "2024-12-18T12:00:00Z",
        category: "NEWS",
    },
    {
        url: "https://kr.aving.net/news/articleView.html?idxno=1795022",
        title: "프롭메이트, 2024 AIoT 국제전시회 참가... 토지 정보 제공 시스템·3D 가상 시뮬레이션 기술 알린다",
        thumbnail: process.env.PUBLIC_URL + "images/1795022.jpg",
        publishedAt: "2024-10-29T12:00:00Z",
        category: "NEWS",
    },
    {
        url: "https://www.getnews.co.kr/news/articleView.html?idxno=700019",
        title: "프롭메이트, AIoT 국제전시회에서 혁신적 솔루션을 선보이다.",
        thumbnail: process.env.PUBLIC_URL + "images/700019.jpg",
        publishedAt: "2024-11-05T12:00:00Z",
        category: "NEWS",
    },
];

export default function News(props) {
    // 뉴스만 표시하므로 간단히 customNews만 State에 저장
    const [newsData, setNewsData] = useState([]);

    // 가로 스크롤 관련 ref & 상태
    const containerRef = useRef(null);
    const [blockStep, setBlockStep] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [lastWheelTime, setLastWheelTime] = useState(0);
    const throttleDelay = 300;

    // 초기 로드 시 customNews를 state에 대입
    useEffect(() => {
        // 날짜 역순 정렬 (publishedAt이 최신순)
        const sorted = [...customNews].sort(
            (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
        );
        setNewsData(sorted);
    }, []);

    // -- 가로 스크롤 계산
    function getBlockStep() {
        return (window.innerWidth * 24) / 100; // 24vw → px
    }

    function easeInOutQuad(t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }

    function animateScrollTo(rawTarget, duration = 300) {
        setIsAnimating(true);
        if (!containerRef.current) return;
        const container = containerRef.current;

        const start = container.scrollLeft;
        const maxScrollLeft = container.scrollWidth - container.clientWidth;
        const target = Math.max(0, Math.min(rawTarget, maxScrollLeft));
        const distance = target - start;
        const startTime = performance.now();

        function step(currentTime) {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);
            const easedValue = easeInOutQuad(progress);

            container.scrollLeft = start + distance * easedValue;
            if (progress < 1) {
                requestAnimationFrame(step);
            } else {
                container.scrollLeft = target;
                setIsAnimating(false);
            }
        }
        requestAnimationFrame(step);
    }

    function scrollToIndex(index, useAnimation = true) {
        const target = index * blockStep;
        if (useAnimation) animateScrollTo(target, 300);
        else if (containerRef.current) {
            containerRef.current.scrollLeft = target;
        }
    }

    // 마우스 휠 → 좌우 스크롤
    function handleWheel(e) {
        e.preventDefault();
        if (isAnimating) return;

        const now = Date.now();
        if (now - lastWheelTime < throttleDelay) return;
        setLastWheelTime(now);

        // 2행 → 컬럼 수 = (newsData.length / 2)
        const totalColumns = Math.ceil(newsData.length / 2);

        if (e.deltaY > 0 && currentIndex < totalColumns - 1) {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
            scrollToIndex(newIndex);
        } else if (e.deltaY < 0 && currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setCurrentIndex(newIndex);
            scrollToIndex(newIndex);
        }
    }

    // 창 크기 변화 시 blockStep 재계산
    useEffect(() => {
        function onResize() {
            setBlockStep(getBlockStep());
            scrollToIndex(0, false);
            setCurrentIndex(0);
        }
        onResize(); // 초기 실행
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    // 휠 이벤트 등록
    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        // 모바일(<= 780px)은 세로 스크롤
        if (window.innerWidth <= 780) return;

        container.addEventListener("wheel", handleWheel, { passive: false });
        return () => container.removeEventListener("wheel", handleWheel);
        // eslint-disable-next-line
    }, [newsData, currentIndex, blockStep, isAnimating, lastWheelTime]);

    return (
        <div id="news">
            {/* 헤더 (필요하면 사용, 그렇지 않다면 제거) */}
            <Header userInfo={props.userInfo} logout={props.logout} />

            <section>
                {/* 왼쪽 메뉴 - 여기서는 카테고리 없이 'NEWS'만 표시 */}
                <div id="newsLeft">
                    <h2>NEWS</h2>
                    <div id="newsMenu">
                        <ul>
                            <li className="newsBtn underline">NEWS</li>
                        </ul>
                    </div>
                    <p>
                        Do you want the latest news? You can find it anywhere!
                        As a news outlet, we are sharing our story so far And
                        broadly. We promise they'll be just as fun and
                        insightful For you as you are to us!
                    </p>
                </div>

                {/* 오른쪽 목록 */}
                <div id="newsRight">
                    <div id="container" ref={containerRef}>
                        {/* 2행 그리드 (desktop), flex-wrap(mobile)은 CSS에서 처리 */}
                        <ul id="newsList">
                            {newsData.map((item, idx) => (
                                <li key={idx} className="news-item">
                                    <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="contentsWrap">
                                            <img
                                                className="bgimg"
                                                src={item.thumbnail}
                                                alt={item.title}
                                            />
                                            <p className="videoTitle">
                                                {item.title}
                                            </p>
                                        </div>
                                        <p className="videoCategory">
                                            {item.title}
                                        </p>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
}
