import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const FullpageHeader = ({
    scrollIndex,
    pagesLength,
    setScrollIndex,
    outerDivRef,
    userInfo,
    logout,
}) => {
    const [btnHover, setBtnHover] = useState("");
    const navigate = useNavigate();
    const clickFunction = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("index"));
        setScrollIndex(index + 1);
        console.log(index);
        outerDivRef.current.style = `transform: translateY(-${
            window.innerHeight * index
        }px)`;
    };
    useEffect(() => {
        setTimeout(function () {
            setBtnHover("hover");
        }, 500);
        setTimeout(function () {
            setBtnHover("");
        }, 2500);
    }, []);
    return (
        <div className="header_bg">
            <div className="width_con">
                <header>
                    <input
                        className="burger-check"
                        type="checkbox"
                        id="burger-check"
                    />
                    <label className="burger-icon" htmlFor="burger-check">
                        <span className="burger-sticks"></span>
                    </label>
                    <div className="menu">
                        <ul className="menu_contents">
                            {userInfo === null ? (
                                <li>
                                    <Link
                                        to="login"
                                        state={{
                                            returnUrl: window.location.href,
                                        }}
                                    >
                                        Log In
                                    </Link>
                                </li>
                            ) : (
                                <>
                                    <li>
                                        <a onClick={logout} href="#">
                                            Log Out
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="Mypage">My page</Link>
                                    </li>
                                </>
                            )}
                            <li className="about">
                                <Link to="company">About</Link>
                            </li>
                            {/* <li className="people"><Link to=''>People</Link></li> */}
                            <li>
                                <a href="/3D">Project</a>
                            </li>
                            <li className="people">
                                <Link to="/staf">People</Link>
                            </li>
                            <li className="news">
                                <Link to="/news">News</Link>
                            </li>
                        </ul>
                    </div>
                    <h1>
                        <a>
                            <img
                                src="images/propmatelogoW.png"
                                alt="프롭메이트 로고"
                            />
                        </a>
                    </h1>
                    <div
                        className={`btns btn_map ${btnHover}`}
                        onClick={() => {
                            navigate("/map");
                        }}
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "images/icon_map_go.png"
                            }
                        ></img>
                        <div className="hover_box">지도 보러가기</div>
                    </div>
                    <div
                        className={`side_nav_con ${
                            scrollIndex == 1 ||
                            scrollIndex == 2 ||
                            scrollIndex == 6
                                ? "hide"
                                : ""
                        }`}
                    >
                        {Array(pagesLength)
                            .fill(0)
                            .map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        index={index}
                                        className="dot"
                                        onClick={clickFunction}
                                    ></div>
                                );
                            })}
                        <div
                            className={`rowLine ${
                                scrollIndex !== 3 ? "hide" : ""
                            }`}
                        ></div>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "images/image_bg_01.svg"
                            }
                            className="background"
                        ></img>
                        <img
                            src={
                                process.env.PUBLIC_URL + "images/image_cube.svg"
                            }
                            className="cube"
                            style={{ top: 30 * (scrollIndex - 1) + "px" }}
                        ></img>
                    </div>
                </header>
            </div>
        </div>
    );
};

export default FullpageHeader;
