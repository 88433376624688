import { useEffect, useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";

function Company(props) {
  const [modalState, setModalState] = useState(false);
  useEffect(()=>{
    props.checkLogin();
  },[]);
  return (
    <div id="Company">
      <Header userInfo={props.userInfo} logout={props.logout}/>
      <main>
        <div className="container">
          <h2 className="mobile_title">회사소개</h2>
          <div className="image_container">
            <img src={process.env.PUBLIC_URL + 'images/company/company.png'} alt="건축물사진" />
          </div>
          <div className="information_container">
            <div className="text_wrap">
              <h2>회사소개</h2>
              <p>
              <span>안녕하세요. 프롭메이트 대표 고호준입니다.</span><br/>

프롭메이트는 지도 기반으로 토지 관련 모든 정보를 신속하고 정확하게 제공하는 전문적인 서비스를 자랑합니다. 저희의 강력한 데이터베이스와 최신 기술을 활용하여, 고객님이 원하는 건물을 지을 수 있는 규모와 예상 비용을 정확히 예측해 드립니다.<br/>

프롭메이트는 건축 가능 용도에 따라 다양한 옵션을 제시하며,  지속적으로 발전하는 기술과 정보로 지혜로운 선택을 돕는 믿을 수 있는 파트너가 되는 것을 목표로 하고 있습니다.<br/>

항상 고객의 만족을 최우선으로 생각하며, 고객들이 저희와 함께 건축 프로세스를 진행할 때 느끼는 안도감과 신뢰는 저희가 가치를 두는 가장 중요한 가치입니다.<br/>

저희와 함께하시면 혁신적인 기술과 믿을 수 있는 신뢰성을 만끽하며, 고객들의 꿈을 현실로 이뤄낼 수 있는 최적의 파트너가 되어드리겠습니다.

              </p>
            </div>
            <div className="download">
              <p>
                <span>Download</span>
              </p>
              <ul className="file_wrap">
                <li>
                  <div className="file_img">
                    <img src={process.env.PUBLIC_URL + 'images/company/file1.png'} alt="IR파일이미지" />
                  </div>
                  <a  href="https://drive.google.com/file/d/10h9p4S8uyTuLN9HyPhJuXPpbrl_eT7XA/view?usp=drive_link" target="_blank" className="file_opacity">
                    <h3>한국어</h3>
                    <p>회사소개서</p>
                  </a>
                </li>
                <li >
                  <div className="file_img">
                    <img src={process.env.PUBLIC_URL + 'images/company/file2.png'} alt="회사소개파일이미지" />
                  </div>
                  <a  href="https://drive.google.com/file/d/1tuWSchZ_wylENrAasQe33jfAEZgOsWHE/view?usp=drive_link" target="_blank" className="file_opacity">
                    <h3>English</h3>
                    <p>Introduction</p>
                  </a>
                </li>
                <li>
                  <div className="file_img">
                    <img src={process.env.PUBLIC_URL + 'images/company/file3.png'} alt="포트폴리오 파일이미지" />
                  </div>
                  <a href="#" className="file_opacity">
                    <h3>français</h3>
                    <p>la présentation de l'entreprise</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      <div className="footer_bg">
        <footer>
          <ul className="footer_info">
            <li><address>주소 | 서울시 관악구 문성로 199-1 2층</address></li>
            <li>대표 | 고호준</li>
            <li>개인정보 관리 책임자 | 이형우</li>
            <li>사업자등록번호 | 469-81-03262</li>
          </ul>
          <ul className="footer_link">
            <li><Link to={'/Company'}>회사소개</Link></li>
            <li><Link to={'/agree'} state={{ index : 0}}>이용약관</Link></li>
            <li><Link to={'/agree'} state={{ index : 1}}>개인정보처리방침</Link></li>
            <li><Link to={'/agree'} state={{ index : 2}}>위치기반 서비스 이용약관</Link></li>
          </ul>
          <p>Copyright 2023 propmat Inc. all rights reserved.</p>
        </footer>
      </div>
      {modalState &&
      
      <div className="modal">
        <div className="modal_content">

          <div className="title">회사소개서 언어 선택</div>
          <div className="btn_wrap">
            <a className="korea">한국어 🇰🇷</a>
            <a className="english">English 🇺🇸</a>
          </div>
          <div className="close_btn" onClick={()=>{setModalState(false);}}></div>
        </div>
      </div>
      
      }
    </div>
  );
}

export default Company;