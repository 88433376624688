import { Routes, Route, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Login from "./components/Login.js";
import Test from "./components/Test.js";
import Draft from "./components/Draft.js";
import "./App.css";
import "./css/reset.css";
import "./css/common.css";
import "./css/Mcommon.css";
import "./css/map.css";
import "./css/login.css";
import "./css/Mlogin.css";
import "./css/company.css";
import "./css/common_respon.css";
import "./css/fullpage.css";
import "./css/staf.css";
import "./css/fullpage_respon.css";
import "./css/Mfullpage.css";
import "./css/mypage.css";
import "./css/mypage_respon.css";
import "./css/3Dviewer.css";
import "./css/3Dviewer_respon.css";
import "./css/fullpage_respon.css";
import "./css/map_respon.css";
import "./css/agree.css";
import Fullpage from "./components/Fullpage.js";
import MFullpage from "./components/MFullpage.js";
import Staf from "./components/Staf.js";
import Mlogin from "./components/Mlogin.js";
import FbxViewer from "./components/FbxViewer.js";
import GltfViewer from "./components/GltfViewer.js";
import Map from "./components/Map";
import Mypage from "./components/Mypage";
import JoinAgree from "./components/JoinAgree.js";
import Viewer3D from "./components/viewer3D.js";
import imageUrls from "./image_preload_url.js";
import Company from "./components/Company";
import News from "./components/News.js";
import axios from "axios";
function App() {
    const [naver] = useState(window.naver);
    const [Kakao] = useState(window.Kakao);
    const [userInfo, setUserInfo] = useState(null);
    const [bookMarks, setBookMarks] = useState(null);
    const navigate = useNavigate();
    //-----------이미지 프리로드------------

    // 이미지를 미리 로드하는 함수를 정의합니다.
    const preloadImages = (urls) => {
        urls.forEach((url) => {
            const img = new Image();
            img.src = url;
            img.onload = () => console.log(`${url} loaded`); // 로딩 완료 시 콘솔에 메시지 출력
            img.onerror = () => console.log(`${url} failed to load`); // 로딩 실패 시 콘솔에 메시지 출력
        });
    };
    //-----------이미지 프리로드------------
    function checkLogin() {
        axios({
            method: "post",
            url: process.env.REACT_APP_LOGINCHECK_SERVER_URL,
            withCredentials: true,
        })
            .then((response) => {
                setUserInfo(response.data);
            })
            .catch((error) => {
                setUserInfo(null);
            });
    }
    function logout() {
        axios({
            method: "post",
            url: process.env.REACT_APP_LOGOUT_SERVER_URL,
            withCredentials: true,
        })
            .then((response) => {
                navigate("/");
                setUserInfo(null);
                console.log("logout 완료");
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status == 401) {
                    setUserInfo(null);
                    console.log("로그인 상태가 아닙니다.");
                }
            });
    }
    function getBookMarks() {
        if (userInfo === null) {
            console.log("로그인이 안되어있습니다.*getBookMarks()");
            return false;
        }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_SERVER}/bookmark/get`,
            data: {
                _id: userInfo._id,
                provider: userInfo.provider,
            },
            withCredentials: true,
        })
            .then((response) => {
                if (response.data === "") {
                    setBookMarks([]);
                } else {
                    setBookMarks(response.data);
                }
                console.log(response.data);
                console.log("북마크들 불러오기 성공");
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status == 401) {
                    setBookMarks(null);
                    console.log(
                        "북마크를 불러들어오지 못했습니다. 로그인 상태가 아닙니다."
                    );
                }
            });
    }
    useEffect(() => {
        // preloadImages(imageUrls); --react content hash (캐싱 관리를위해 이미지 이름에 랜덤 문자열 더해짐) 으로인해 preload 안됨
    }, []);

    useEffect(() => {
        getBookMarks();
    }, [userInfo]);
    return (
        <>
            <div className="CrossApp">
                <BrowserView>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Fullpage
                                    userInfo={userInfo}
                                    checkLogin={checkLogin}
                                    logout={logout}
                                />
                            }
                        ></Route>
                        <Route
                            path="/M"
                            element={
                                <MFullpage
                                    userInfo={userInfo}
                                    checkLogin={checkLogin}
                                    logout={logout}
                                />
                            }
                        ></Route>
                        <Route path="/test" element={<Test />}></Route>
                        <Route
                            path="/Login"
                            element={
                                <Login
                                    naver={naver}
                                    Kakao={Kakao}
                                    userInfo={userInfo}
                                    checkLogin={checkLogin}
                                    logout={logout}
                                />
                            }
                        ></Route>
                        <Route path="/draft" element={<Draft />}></Route>
                        {/* <Route path="/fbxviewer" element={<FbxViewer/>}></Route>
            <Route path="/gltfViewer" element={<GltfViewer/>}></Route> */}
                        <Route
                            path="/map"
                            element={
                                <Map
                                    userInfo={userInfo}
                                    checkLogin={checkLogin}
                                    bookMarks={bookMarks}
                                    setBookMarks={setBookMarks}
                                />
                            }
                        ></Route>
                        <Route
                            path="/company"
                            element={
                                <Company
                                    userInfo={userInfo}
                                    logout={logout}
                                    checkLogin={checkLogin}
                                />
                            }
                        ></Route>
                        <Route
                            path="/news"
                            element={
                                <News
                                    userInfo={userInfo}
                                    logout={logout}
                                    checkLogin={checkLogin}
                                />
                            }
                        ></Route>
                        <Route
                            path="/mypage"
                            element={
                                <Mypage
                                    userInfo={userInfo}
                                    bookMarks={bookMarks}
                                    checkLogin={checkLogin}
                                    getBookMarks={getBookMarks}
                                    logout={logout}
                                />
                            }
                        ></Route>
                        <Route
                            path="/3D"
                            element={
                                <Viewer3D userInfo={userInfo} logout={logout} />
                            }
                        ></Route>
                        <Route
                            path="/agree"
                            element={
                                <JoinAgree
                                    userInfo={userInfo}
                                    logout={logout}
                                />
                            }
                        ></Route>
                        <Route
                            path="/staf"
                            element={
                                <Staf userInfo={userInfo} logout={logout} />
                            }
                        ></Route>
                    </Routes>
                </BrowserView>
                <MobileView>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <MFullpage
                                    userInfo={userInfo}
                                    logout={logout}
                                    checkLogin={checkLogin}
                                />
                            }
                        ></Route>
                        <Route path="/test" element={<Test />}></Route>
                        <Route
                            path="/Login"
                            element={
                                <Mlogin
                                    naver={naver}
                                    Kakao={Kakao}
                                    userInfo={userInfo}
                                    checkLogin={checkLogin}
                                    logout={logout}
                                />
                            }
                        ></Route>
                        <Route path="/draft" element={<Draft />}></Route>
                        {/* <Route path="/fbxviewer" element={<FbxViewer/>}></Route>
            <Route path="/gltfViewer" element={<GltfViewer/>}></Route> */}
                        <Route
                            path="/map"
                            element={
                                <Map
                                    userInfo={userInfo}
                                    checkLogin={checkLogin}
                                    bookMarks={bookMarks}
                                    setBookMarks={setBookMarks}
                                />
                            }
                        ></Route>
                        <Route
                            path="/company"
                            element={
                                <Company
                                    userInfo={userInfo}
                                    logout={logout}
                                    checkLogin={checkLogin}
                                />
                            }
                        ></Route>
                        <Route
                            path="/news"
                            element={
                                <News
                                    userInfo={userInfo}
                                    logout={logout}
                                    checkLogin={checkLogin}
                                />
                            }
                        ></Route>
                        <Route
                            path="/mypage"
                            element={
                                <Mypage
                                    userInfo={userInfo}
                                    bookMarks={bookMarks}
                                    checkLogin={checkLogin}
                                    getBookMarks={getBookMarks}
                                    logout={logout}
                                />
                            }
                        ></Route>
                        <Route
                            path="/3D"
                            element={
                                <Viewer3D userInfo={userInfo} logout={logout} />
                            }
                        ></Route>
                        <Route
                            path="/agree"
                            element={
                                <JoinAgree
                                    userInfo={userInfo}
                                    logout={logout}
                                />
                            }
                        ></Route>
                        <Route
                            path="/staf"
                            element={
                                <Staf userInfo={userInfo} logout={logout} />
                            }
                        ></Route>
                    </Routes>
                </MobileView>
            </div>
        </>
    );
}

export default App;
