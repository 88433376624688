import { useEffect, useState } from "react";
import { mapUtil } from "../util/mapUtil";



function CmprsEstmt(props) {
  const [selectedInfo, setSelectedInfo] = useState([]);
  const [buildingPurposeIndex, setBuildingPurposeIndex] = useState(0);
  window.setSelectedInfo = setSelectedInfo;
  const buildingPurpose = ['단독주택', '공동주택', '제1종 근린생활시설', '제2종 근린생활시설', '문화 및 집회시설', '판매시설', '운동시설', '숙박시설', '의료시설', '교육연구시설', '노유자시설', '업무시설', '종교시설'];
  useEffect(() => {
    console.log(props.selectedOptions);
    const fetchData = async () => {
      try {
        const promises = props.selectedOptions.map(async (item) => {
          try {
            const responseData = await mapUtil.bookMarkSearch(item.bookmark_id);
            const bookMarkData = {...responseData,...item};
            return bookMarkData;
          } catch (error) {
            console.log(error);
            return null;
          }
        });

        const bookMarkData = await Promise.all(promises);
        setSelectedInfo(bookMarkData);
        console.log(bookMarkData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [props.selectedOptions]);


  return (
    <div className={`consulting_con ${props.infoClick} cmprsEstmt`} style={selectedInfo.length === 0 || props.selectedOptions.length === 0 ? { alignItems: 'center' } : {}}>
      {selectedInfo.length === 0 || props.selectedOptions.length === 0 ?
        <div className="empty_message">
          {props.selectedOptions.length === 0 ? "견적 비교할 토지들을 선택해주세요!" : "견적 비교를 위해 데이터를 불러오는 중입니다."}
          
        </div> 
        :
        <>
          <div className="head_wrap">
            <div className="content selectBox">
              <div className="selectWrap">
                <div className="allowBuildingTitle">건축가능용도</div>
                <select className="allowBuildingList" value={buildingPurposeIndex} onChange={(e) => { setBuildingPurposeIndex(e.target.value) }}>
                  {buildingPurpose.map((item, index) => {
                    return (
                      <option key={index} value={index}>{item}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="table_head">
              <div className="head">토지 면적</div>
              <div className="head">건축 면적</div>
              <div className="head">건폐율</div>
              <div className="head">연면적</div>
              <div className="head">용적률</div>
              <div className="head">예상 공사비</div>
            </div>

          </div>
          <div className="info">
            <div className="address_info_wrap">
              {selectedInfo.map((item, index) => {
                const selected_purpose = buildingPurpose[buildingPurposeIndex];
                const selected_allow_purpose = Object.values(item.estimateList).filter(obj => obj.mainCategoryName === selected_purpose);
                return (
                  <>
                    {selected_allow_purpose === undefined || selected_allow_purpose.length === 0 ?
                      <div key={index} className="address_info">
                        <div className="not_allow">{selected_purpose} 건축 불가</div>
                        <div className="content selectBox">
                          {item.allowBuildingList.allowBuildingListSubCategory.length === 0 ?
                            "건축불가" :
                            <>
                              <div className="building_list_text">건축 가능 용도</div>
                              <select className="allowBuildingList">
                                {item.allowBuildingList.allowBuildingListSubCategory.map((item, index) => {
                                  return <option key={index} value={index}>{item.name.replace('。', '')}</option>;
                                })}
                              </select>
                            </>
                          }
                        </div>
                      </div>


                      :
                      <div key={index}>
                        <div className='bookmark'>
                          <div className="color" style={item.bookMarkListColor === undefined ? { background: "transparent" } : { background: `${item.bookMarkListColor}` }}></div>
                          <div className="name">{item.landName}</div>
                          <div className="title">{item.name.jibunAddress}</div>
                          <div className="plot">대지면적 : {item.construct.PLOT_AR}㎡ ({(item.construct.PLOT_AR * 0.3025).toFixed(2)}평)</div>
                        </div>
                        <div className="all_info">
                          <div className="info_title_M">
                            <p>토지 면적</p>
                            <p>건축 면적</p>
                            <p>건폐율</p>
                            <p>연면적</p>
                            <p>용적률</p>
                            <p>예상 공사비</p>
                          </div>
                          <div className="address_info">
                            <div className="content">{item.land.size}㎡<span>({(item.land.size * 0.3025).toFixed(2)}평)</span></div>
                            <div className="content">{item.construct.PLOT_AR}㎡<span>({(item.construct.PLOT_AR * 0.3025).toFixed(2)}평)</span></div>
                            <div className="content">{item.gyData.g}%</div>
                            <div className="content">{item.construct.TOTAR}㎡<span>({(item.construct.TOTAR * 0.3025).toFixed(2)}평)</span></div>
                            <div className="content">{item.gyData.y}%</div>
                            <div className="content"><span className="total">총액&nbsp;&nbsp;</span>{selected_allow_purpose[0].estimate.total}</div>
                          </div>
                        </div>
                      </div>
                    }
                  </>

                )
              })}
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default CmprsEstmt;