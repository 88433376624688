import axios from "axios";
/* global MarkerClustering */
Number.prototype.between = function(a, b) {
    var min = Math.min.apply(Math, [a, b]),
        max = Math.max.apply(Math, [a, b]);
    return this > min && this < max;
};

export const mapUtil = {
    async changeMapAddr(latlng, jibunAddress, roadAddress){
        return new Promise((resolve,reject)=>{

            let infoWindowContent = [];
            let lng = latlng[1] * 1;
            let coordType = '';
            if (lng.between(124.5, 126)) {
                coordType = 'west';
            } else if (lng.between(126, 128)) {
                coordType = 'center';
            } else if (lng.between(128, 130)) {
                coordType = 'east';
            } else {
                coordType = 'center';
            }

            console.log(coordType);

            if (roadAddress === '' && jibunAddress === '') {
                window.setModalMessage('올바른 주소를 입력 혹은 클릭해주세요.');
                return -1;
            }

            
            window.reqLoad = axios({
                method: 'get',
                url: process.env.REACT_APP_MAP_SERVER_URL,
                params: {
                    address: (roadAddress === '') ? jibunAddress : roadAddress,
                    addressType: (roadAddress === '') ? 'PARCEL' : 'road',
                    'coordType' : coordType
                }
                }).then(data => {
                    console.log(data);
                    data = data.data;
                    if(data === '검색 결과가 없습니다.'){
                        window.setModalMessage('검색 결과가 없습니다.');
                        return;
                    }
                    data.name = {
                        roadAddress : roadAddress,
                        jibunAddress : jibunAddress
                    }
                    if (data.status === "fail"){
                        console.log(data.message.message);
                        return;
                    }
                    if (jibunAddress !== '') {
                        infoWindowContent.push(jibunAddress);
                    }
                    if(!data.construct){
                        window.setModalMessage('검색에 실패하였습니다.');
                        return;
                    }
                    
                    let constructInfo = data.construct;
                    let PLOT_AR = constructInfo.PLOT_AR; // 토지 면적
                    let TOTAR = constructInfo.TOTAR; // 연면적
                    let SHAPE_PATH = [];
                    let landInfo = data.land;
                    let SHAPE = landInfo.shapes;
                    SHAPE.forEach(item => {
                        SHAPE_PATH.push(
                            window.naver.maps.UTMK.fromUTMKToCoord(
                                new window.naver.maps.Point(item.x, item.y)
                            )
                        );
                    });

                    if (landInfo.type === "하천"){
                        window.setModalMessage("하천은 선택하실 수 없습니다.");
                        return;
                    } else if (landInfo.type === "도로"){
                        window.setModalMessage("도로는 선택하실 수 없습니다.");
                        return;
                    }
                    if (window.mapPoly)
                        window.mapPoly.setMap(null);

                    if (SHAPE_PATH.length !== 0){
                        window.mapPoly = new window.naver.maps.Polygon({
                            paths: [
                                SHAPE_PATH
                            ],
                            fillColor: '#AB35D5',
                            fillOpacity: 0.15,
                            strokeColor: 'url(#myGradient)',
                            strokeOpacity: 0.6,
                            strokeWeight: 3
                        })
                        window.mapPoly.setMap(window.map);
                    }
                    document.body.scrollIntoView(false);

                    if(landInfo.center === undefined){
                        window.setModalMessage('검색에 실패하였습니다.');
                        return;

                    }
                    
                    window.infoWindow.open(window.map,
                        window.naver.maps.UTMK.fromUTMKToCoord(
                            new window.naver.maps.Point(landInfo.center.x, landInfo.center.y)
                        )
                    );
                    resolve(data);
                    
                }).catch(error =>{
                    console.log(error);
                    reject(error);
                })
        });
        
    },
    async searchCoordinateToAddress(latlng) {
  
        window.naver.maps.Service.reverseGeocode({
            coords: latlng,
            orders: [
                window.naver.maps.Service.OrderType.ADDR,
                window.naver.maps.Service.OrderType.ROAD_ADDR
            ].join(',')
        }, function(status, response) {
            if (status === window.naver.maps.Service.Status.ERROR) {
                if (!latlng) {
                    return alert('ReverseGeocode Error, Please check latlng');
                }
                if (latlng.toString) {
                    return alert('ReverseGeocode Error, latlng:' + latlng.toString());
                }
                if (latlng.x && latlng.y) {
                    return alert('ReverseGeocode Error, x:' + latlng.x + ', y:' + latlng.y);
                }
                return alert('ReverseGeocode Error, Please check latlng');
            }
            window.map.panTo(latlng);
            mapUtil.changeMapAddr(latlng, response.v2.address.jibunAddress, response.v2.address.roadAddress).then(data=>{
                window.setAddressInfo(data);
            }).catch(error=>{
                console.log(error);
                window.setModalMessage(error);
            })
  
        });
      },
      async onlySearchCoordinateToAddress(latlng) {
        return new Promise((resolve, reject) => {
          window.naver.maps.Service.reverseGeocode({
            coords: latlng,
            orders: [
              window.naver.maps.Service.OrderType.ADDR,
              window.naver.maps.Service.OrderType.ROAD_ADDR
            ].join(',')
          }, function(status, response) {
            if (status === window.naver.maps.Service.Status.ERROR) {
              // 에러 발생 시 reject 처리
              reject(new Error('ReverseGeocode Error, Please check latlng: ' + (latlng.toString ? latlng.toString() : '')));
            } else {
              // 성공 시 resolve 처리
              resolve({
                jibunAddress : response.v2.address.jibunAddress, 
                roadAddress : response.v2.address.roadAddress
              });
            }
          });
        });
      },

    searchAddressToCoordinate(address) {
        window.naver.maps.Service.geocode({
            query: address
        }, function(status, response) {
            if (status === window.naver.maps.Service.Status.ERROR) {
                if (!address) {
                    window.setModalMessage('주소를 찾을 수 없습니다.');
                    return;
                }
                return alert('Geocode Error, address:' + address);
            }
  
            if (response.v2.meta.totalCount === 0) {
                window.setModalMessage('주소를 찾을 수 없습니다.');
                return;
            }
            window.map.panTo([response.v2.addresses[0].x, response.v2.addresses[0].y]);
            mapUtil.changeMapAddr([response.v2.addresses[0].x, response.v2.addresses[0].y], response.v2.addresses[0].jibunAddress, response.v2.addresses[0].roadAddress).then(data=>{
                window.setAddressInfo(data);
            }).catch(error=>{
              console.log(error);
              window.setModalMessage(error);
            })
            // window.map.setCenter(point);
        });
    },
    parseBudgetString(str) {
        // "10억" 같은 문자열에서 숫자만 빼기
        // 정규식으로 숫자만 추출 or 문자열 치환
        const numPart = str.replace(/[^\d]/g, '');  // "10억" → "10"
        // 억 단위면 numPart * 1억
        const value = parseInt(numPart, 10) * 100000000; 
        return value;  // 원 단위 반환
      },
    async onlySearchAddressToCoordinate(address) {
        return new Promise((resolve, reject) => {
          window.naver.maps.Service.geocode({
            query: address
          }, function(status, response) {
            if (status === window.naver.maps.Service.Status.ERROR) {
              reject('지오코딩 에러');
              return;
            }
            if (!response.v2.meta||response.v2.meta.totalCount === 0) {
              reject('검색 결과 없음');
              return;
            }
            const x = parseFloat(response.v2.addresses[0].x);
            const y = parseFloat(response.v2.addresses[0].y);
      
            // **여기서 panTo 등 지도 이동 로직 전혀 없음**
            resolve([x, y]);
          });
        });
      },

    async addressToCoordinateForTest(address) {
        return new Promise((resolve, reject) => {
            window.naver.maps.Service.geocode({
                query: address
            }, function(status, response) {
                if (status === window.naver.maps.Service.Status.ERROR) {
                    if (!address) {
                        window.setModalMessage('주소를 찾을 수 없습니다.');
                        reject('주소 오류');
                        return;
                    }
                    reject(new Error('Geocode Error'));
                    return;
                }
        
                if (response.v2.meta.totalCount === 0) {
                    window.setModalMessage('주소를 찾을 수 없습니다.');
                    reject('검색 결과 없음');
                    return;
                }
                
                // 지도 이동 및 처리
                // window.map.panTo([response.v2.addresses[0].x, response.v2.addresses[0].y]);
                mapUtil.changeMapAddr([response.v2.addresses[0].x, response.v2.addresses[0].y], response.v2.addresses[0].jibunAddress, response.v2.addresses[0].roadAddress)
                    .then(data => {
                        window.setAddressInfo(data);
                        resolve(data); // 검색 결과를 반환
                    })
                    .catch(error => {
                        console.log(error);
                        reject(error);
                    });
            });
        });
    },

    bookMarkSearch(address) {
        return new Promise((resolve, reject) => {
            window.naver.maps.Service.geocode({
                query: address
            }, function(status, response) {
                if (status === window.naver.maps.Service.Status.ERROR) {
                    if (!address) {
                        window.setModalMessage('주소를 찾을 수 없습니다.');
                        reject('주소를 찾을 수 없습니다.');
                        return;
                    }
                    alert('Geocode Error, address:' + address);
                    reject('Geocode Error');
                    return;
                }
    
                if (response.v2.meta.totalCount === 0) {
                    window.setModalMessage('주소를 찾을 수 없습니다.');
                    reject('주소를 찾을 수 없습니다.');
                    return;
                }
                



    
                mapUtil.getAddrInfo([response.v2.addresses[0].x, response.v2.addresses[0].y], response.v2.addresses[0].jibunAddress, response.v2.addresses[0].roadAddress)
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => {
                        console.log(error);
                        window.setModalMessage(error);
                        reject(error);
                    });
            });
        });
    },
    async getAddrInfo(latlng, jibunAddress, roadAddress){
        return new Promise((resolve,reject)=>{

            let infoWindowContent = [];
            let lng = latlng[1] * 1;
            let coordType = '';
            if (lng.between(124.5, 126)) {
                coordType = 'west';
            } else if (lng.between(126, 128)) {
                coordType = 'center';
            } else if (lng.between(128, 130)) {
                coordType = 'east';
            } else {
                coordType = 'center';
            }

            console.log(coordType);

            if (roadAddress === '' && jibunAddress === '') {
                window.setModalMessage('올바른 주소를 입력 혹은 클릭해주세요.');
                return -1;
            }


            window.reqLoad = axios({
                method: 'get',
                url: process.env.REACT_APP_MAP_SERVER_URL,
                params: {
                    address: (roadAddress === '') ? jibunAddress : roadAddress,
                    addressType: (roadAddress === '') ? 'PARCEL' : 'road',
                    'coordType' : coordType
                }
                }).then(data => {
                    data = data.data;
                    data.name = {
                        roadAddress : roadAddress,
                        jibunAddress : jibunAddress
                    }
                    if (data.status === "fail"){
                        console.log(data.message.message);
                        return;
                    }
                    if (jibunAddress !== '') {
                        infoWindowContent.push(jibunAddress);
                    }
                    if(!data.construct){
                        window.setModalMessage('검색에 실패하였습니다.');
                        return;
                    }
                    
                    let landInfo = data.land;

                    if (landInfo.type === "하천"){
                        window.setModalMessage("하천은 선택하실 수 없습니다.");
                        return;
                    } else if (landInfo.type === "도로"){
                        window.setModalMessage("도로는 선택하실 수 없습니다.");
                        return;
                    }
                    resolve(data);
                    
                }).catch(error =>{
                    console.log(error);
                    reject(error);
                })
        });
        
    },  

    async getTransactionPrice(address,item){
        return new Promise((resolve,reject)=>{
            window.reqLoad = axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_SERVER}/api/transactionPrice`,
                params: {
                    address: address,
                    category : item
                }
                }).then(data => {
                    console.log(data);
                    data = data.data;
                    if (data.status === "fail"){
                        console.log(data.message.message);
                        return;
                    }
                    resolve(data);
                    
                }).catch(error =>{
                    console.log(error);
                    reject(error);
                })
        });
    },

    allMarkers: [],
    MarkerClustering: null,
    async markTransactionPrices(transactionPrices,category,budget=[]) {
        console.log('Original transactionPrices:', transactionPrices);
        console.log(budget);
        // 1) transactionPrices가 배열인지 검사 (간혹 {0:{...}, 1:{...}} 형태의 객체일 수 있음)
        if (!Array.isArray(transactionPrices)) {
          transactionPrices = Object.values(transactionPrices);
        }
      
        // 2) 각 항목에서 키/값에 붙은 큰따옴표("...") 제거(후처리)
        //    CSV 파싱할 때 제거하도록 수정할 수도 있지만, 여기서 한 번 더 방어적으로 처리
        transactionPrices = transactionPrices.map(item => {
          const newObj = {};
          for (const [key, value] of Object.entries(item)) {
            // key에서 양 끝 큰따옴표 제거
            const cleanKey = key.replace(/^"|"$/g, '').trim();
            let cleanValue = value;
            if (typeof value === 'string') {
              // 값이 문자열이면 값에서도 양 끝 큰따옴표 제거
              cleanValue = value.replace(/^"|"$/g, '').trim();
            }
            newObj[cleanKey] = cleanValue;
          }
          return newObj;
        });
        let filteredPrices = transactionPrices;
        if(budget.length === 2){

            const minBudget = this.parseBudgetString(budget[0]) || 0;
            const maxBudget = this.parseBudgetString(budget[1]) || 999999999;
        console.log(    minBudget,maxBudget);
            console.log('Cleaned transactionPrices:', transactionPrices);
            filteredPrices = transactionPrices.filter(item => {
                const 거래금액 = item["거래금액(만원)"] || '';
                // 콤마 제거
                const raw = 거래금액.toString().replace(/,/g, '');
                // 만원 → 원
                const amountInWon = parseInt(raw, 10) * 10000;
                if (isNaN(amountInWon)) return false;
          
                // minBudget ~ maxBudget 범위 검사
                return (amountInWon >= minBudget && amountInWon <= maxBudget);
              });
            console.log('Cleaned filteredPrices:',filteredPrices);
          
        }
        const markers = [];
      
        // 3) 각 항목을 순회하여 지도에 마커 표시
        for (const item of filteredPrices) {
          // 시군구, 번지 필드가 존재하는지 확인
          const 시군구 = item["시군구"] || '';   // 예: "서울특별시 동대문구 답십리동"
          const 번지 = item["번지"] || '';       // 예: "488-18"
          const 거래금액 = item["거래금액(만원)"] || '';
      
          // 주소 문자열 생성 ("시군구 + 번지")
          const fullAddress = `${시군구} ${번지}`.trim();
          if (!fullAddress) {
            console.log('주소 정보 부족:', item);
            continue;
          }
      
          // 4) 주소 → 좌표 변환
          let geocodeResult;
          try {
            // 반환값은 [x, y] = [경도, 위도]
            geocodeResult = await this.onlySearchAddressToCoordinate(fullAddress);
          } catch (err) {
            console.error('지오코딩 실패:', fullAddress, err);
            continue;
          }
      
          if (!geocodeResult) {
            console.log('좌표정보가 없습니다:', fullAddress, geocodeResult);
            continue;
          }
      
          const [lng, lat] = geocodeResult;
      
          const coord = new window.naver.maps.LatLng(lat, lng);
          const raw = 거래금액.replace(/,/g, '');
          const amountInWon = parseInt(raw, 10) * 10000;
    
          
          function convertToEok(value) {
            // 입력값을 문자열로 변환 후, 콤마 제거
            const cleanValue = value.toString().replace(/,/g, '');
            // 숫자로 변환
            const num = parseFloat(cleanValue);
            if (isNaN(num)) {
              console.error("올바른 숫자가 아닙니다.");
              return null;
            }
            // 1억은 10,000만원이므로 10000으로 나눔
            const eok = num / 10000;
            // 소수점 둘째 자리까지 포맷 후 "억" 단위 추가
            return eok.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            });
          }
          // 5) 마커 생성
          const marker = new window.naver.maps.Marker({
            position: coord,
            map: null,
            icon:{
                content: `
                  <div class="transactionWindow ${category}">
                    <div class="title">${category}</div>
                    <div class="textWrap">실거래가</div>
                    <div class="price">${convertToEok(거래금액)}억</div>
                  </div>
                `,
                  size: new window.naver.maps.Size(90, 124),
                  anchor: new window.naver.maps.Point(45, 124),

            },
            title:category,
          });

        // 마커별로 금액정보 저장 -> updateMarkersByBudget() 때 필터링에 사용
        marker.userData = {
            amountInWon: amountInWon,
            category,
        };

          markers.push(marker);
          // (참고) 만약 클릭 시에만 열리고 싶다면:
          // window.naver.maps.Event.addListener(marker, 'click', () => {
          //   infoWindow.open(window.map, marker);
          // });
        }
      this.allMarkers.push(...markers);

  // 4) MarkerClustering 객체 생성
  //    - markers 배열을 넘김
  //    - 여러 옵션으로 클러스터 아이콘/스타일 customizing 가능
  const clusterMarkerSize = 60;
  if(!this.MarkerClustering){
    console.log("-----------------------------debug-----------------------------");
    console.log(MarkerClustering);
    this.MarkerClustering = new MarkerClustering({
        map: window.map,      // 지도 객체
        markers: markers,     // 마커 배열
        minClusterSize: 1,    // 2개 이상 모이면 클러스터 생성
        maxZoom: 15,          // 이 줌 이하에서만 클러스터링
        gridSize: 500,
        disableClickZoom: false,
    
        // (선택) 클러스터 아이콘
        icons: [
          {
            content: `
              <div class="clusterMarker" style="
                width:${clusterMarkerSize}px; height:${clusterMarkerSize}px; 
                background-color:rgba(88,61,240,0.6);
                border-radius:50%;
                display:flex; 
                justify-content:center;
                align-items:center;
                color:#fff; 
                font-weight:bold;
              ">
              </div>
            `,
            size: new window.naver.maps.Size(clusterMarkerSize,clusterMarkerSize),
            anchor: new window.naver.maps.Point(clusterMarkerSize/2,clusterMarkerSize/2)
          }
        ],
    
        // (선택) "마커 개수"에 따라 아이콘 인덱스를 고르는 규칙
        indexGenerator: [10, 20, 30, 50, 100],
    
        // (선택) "stylingFunction"으로 클러스터 아이콘에 마커개수 삽입
        stylingFunction: function(clusterMarker, count) {
          const element = clusterMarker.getElement();
          // clusterMarker.getCluster() 로 클러스터 정보도 얻을 수 있음
          element.innerHTML = `<div style="line-height:${clusterMarkerSize}px; color:#eee; margin-bottom:-${clusterMarkerSize}px; width:${clusterMarkerSize}px; height:${clusterMarkerSize}px; text-align:center; font-size:${clusterMarkerSize/3}px; font-weight:600">${count}</div>`;
        }
      });
  }else{
    this.MarkerClustering.setMarkers(this.allMarkers);
  }
  

    },

    async updateMarkersByBudget(minBudget, maxBudget) {
        this.allMarkers = [];
        minBudget = this.parseBudgetString(minBudget);
        maxBudget = this.parseBudgetString(maxBudget);
        if(minBudget === maxBudget){
            minBudget = minBudget - 100000000;
            maxBudget = maxBudget + 100000000;
        }
        // 1) allMarkers 중에서 amountInWon이 min~max 사이에 있는 마커만 추출
        const filteredMarkers = this.allMarkers.filter(marker => {
          if (!marker.userData || typeof marker.userData.amountInWon !== 'number') {
            return false;
          }
          const val = marker.userData.amountInWon;
          return val >= minBudget && val <= maxBudget;
        });
        console.log(filteredMarkers);
        this.allMarkers=filteredMarkers;
        // 2) 기존 MarkerClustering이 있다면, setMarkers()로 새 목록 할당
        if (this.MarkerClustering) {
          this.MarkerClustering.setMarkers(filteredMarkers);
        } else {
          // 혹시 생성이 안 되어 있었다면 새로 생성
          const clusterMarkerSize = 60;
          this.MarkerClustering = new MarkerClustering({
            map: window.map,
            markers: filteredMarkers,
            minClusterSize: 1,
            maxZoom: 16,
            gridSize: 500,
            disableClickZoom: false,
            icons: [
              {
                content: `
                  <div class="clusterMarker"
                       style="width:${clusterMarkerSize}px; height:${clusterMarkerSize}px;
                              background-color:rgba(88,61,240,0.6);
                              border-radius:50%; display:flex; justify-content:center;
                              align-items:center; color:#fff; font-weight:bold;"
                  ></div>
                `,
                size: new window.naver.maps.Size(clusterMarkerSize, clusterMarkerSize),
                anchor: new window.naver.maps.Point(clusterMarkerSize/2, clusterMarkerSize/2)
              }
            ],
            indexGenerator: [10, 20, 30, 50, 100],
            stylingFunction: function(clusterMarker, count) {
              const el = clusterMarker.getElement();
              el.innerHTML = `
                <div style="
                  line-height:${clusterMarkerSize}px;
                  color:#eee; margin-bottom:-${clusterMarkerSize}px;
                  width:${clusterMarkerSize}px; height:${clusterMarkerSize}px;
                  text-align:center; font-size:${(clusterMarkerSize/3)}px; font-weight:600
                ">
                  ${count}
                </div>`;
            }
          });
        }
      }
};