
import { Link, useNavigate } from "react-router-dom";

const FullpageHeader = ({ userInfo , logout}) => {
    const navigate =useNavigate();
    
    return(
        <div className="header_bg">
            <div className="width_con">
                <header>
                    <input className="burger-check" type="checkbox" id="burger-check" />
                    <label className="burger-icon" htmlFor="burger-check">
                    <span className="burger-sticks"></span>
                    </label>
                    <div className="menu">
                        <ul className="menu_contents">
                            {userInfo === null ? 
                            <li><Link to='/Login' state={{ returnUrl : window.location.href}}>Log In</Link></li> :
                            <>                 
                            <li><a onClick={logout} href="#">Log Out</a></li>
                            <li><Link to='/mypage'>My page</Link></li>
                            </>
                            }
                            <li className="about"><Link to='/company'>About</Link></li>
                            {/* <li className="people"><Link to=''>People</Link></li> */}
                            <li><a href="/3D">Project</a></li>
                            <li className="people"><Link to='/staf'>People</Link></li>
                            <li className="news">
                                <Link to="/news">News</Link>
                            </li>
                        </ul>
                    </div>
                </header>                
                <div className={`btns btn_map`} onClick={()=>{navigate('/map')}}>
                    <img src={process.env.PUBLIC_URL+"images/icon_map_go.png"}></img>
                <div className="hover_box">
                    지도 보러가기
                </div>
                </div>
            </div>
        </div>
    )
}

export default FullpageHeader;